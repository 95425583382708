<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-body>
                <div class="mb-2">
                    <mdb-btn color="secondary"
                             @click="add_repair_target()">
                        <i class="fa fa-plus mr-2"></i>
                        &nbsp;新增維修機種
                    </mdb-btn>
                </div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th style="width:3em">#</th>
                            <th class="text-nowrap"
                                style="cursor:pointer;width:10rem"
                                @click="sort_data('brand')">
                                廠牌
                                <i class="ml-2 fa"
                                   :class="{'fa-sort':search.sort_brand=='','fa-sort-up':search.sort_brand=='asc','fa-sort-down':search.sort_brand=='desc'}"></i>
                            </th>
                            <th class="text-nowrap"
                                style="cursor:pointer;width:15rem"
                                @click="sort_data('name')">
                                名稱
                                <i class="ml-2 fa"
                                   :class="{'fa-sort':search.sort_name=='','fa-sort-up':search.sort_name=='asc','fa-sort-down':search.sort_name=='desc'}"></i>
                            </th>
                            <th style="width:15em">顏色</th>
                            <th class="text-center text-nowrap"
                                style="width:10em"
                                @click="sort_data('active')">啟用
                                <i class="ml-2 fa"
                                   :class="{'fa-sort':search.sort_active=='','fa-sort-up':search.sort_active=='asc','fa-sort-down':search.sort_active=='desc'}"></i>
                            </th>
                            <th> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(s,sk) in repair_targetList"
                            :key="`repair_target_${sk}`">
                            <td>{{(sk+1)+(data.nowPage-1)*10}}</td>
                            <td class="text-nowrap">
                                <span v-show="!s.edit">{{repair_target_brandList[s.brand]?repair_target_brandList[s.brand]:''}}</span>
                                <select class="form-control"
                                        v-model="s.brand"
                                        v-show="s.edit">
                                    <option value=""
                                            selected
                                            disabled>選擇廠牌</option>
                                    <option v-for="(b,bk) in repair_target_brandList"
                                            :value="bk"
                                            :key="`rtb_${bk}`">{{b}}</option>
                                </select>
                            </td>
                            <td class="text-nowrap"
                                @keypress.enter="save_repair_target(s)">
                                <span v-show="!s.edit">{{s.name}}</span>
                                <mdb-input type="text"
                                           v-show="s.edit"
                                           v-model="s.name"
                                           class="my-0"
                                           placeholder="請輸入名稱" />
                            </td>
                            <td>
                                {{s.colors.join('，')}}
                                {{s.colors.length==0?'無':''}}
                                <br v-show=" s.colors.length>0">
                                <mdb-btn color="secondary"
                                         size="sm"
                                         @click="edit_repair_target_colors(s)"
                                         v-show="s.edit">
                                    <i class="fas fa-palette mr-2"></i>
                                    編輯
                                </mdb-btn>
                            </td>
                            <td class="text-center">
                                <span v-show="!s.edit">
                                    <i class="fa"
                                       :class="s.active?'fa-check':'fa-times'"></i>
                                </span>
                                <mdb-switch offLabel=""
                                            onLabel=''
                                            :checked="s.active"
                                            v-show="s.edit"
                                            @getValue="val=>s.active=val" />
                            </td>
                            <td>
                                <mdb-btn class="text-nowrap"
                                         color="primary"
                                         size="sm"
                                         @click="s.edit=true"
                                         v-show="!s.edit">
                                    <i class="fa fa-edit mr-2"></i>
                                    編輯
                                </mdb-btn>
                                <mdb-btn class="text-nowrap"
                                         color="default"
                                         size="sm"
                                         @click="save_repair_target(s)"
                                         v-show="s.edit">
                                    <i class="fa fa-save mr-2"></i>
                                    儲存
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="5"
                                v-show="repair_targetList.length==0">無資料</td>
                        </tr>
                    </tbody>
                </table>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;getData();}" />
            </mdb-card-body>
        </mdb-card>
        <!-- 編輯維修項目顏色資料 start -->
        <div>
            <mdb-modal size="md"
                       :show="edit_colors.show"
                       @close="edit_colors.show=false">
                <mdb-modal-header class="text-center">顏色選項 - {{edit_colors.repair_target.name}}
                </mdb-modal-header>
                <mdb-modal-body class="
                                  mx-3
                                  grey-text">
                    <mdb-btn class="mb-2"
                             size="sm"
                             @click="edit_colors.data.push('')"><i class="fa fa-plus"></i>&nbsp;新增顏色</mdb-btn>
                    <mdb-row v-for="(c,ck) in edit_colors.data"
                             :key="`c_${ck}`">
                        <mdb-col col="10">
                            <mdb-input class="m-0"
                                       placeholder="請輸入顏色"
                                       v-model="edit_colors.data[ck]"></mdb-input>
                        </mdb-col>
                        <mdb-col col="2">
                            <mdb-btn color="danger"
                                     size="sm"
                                     @click="edit_colors.data.splice(ck,1)">
                                <i class="fa fa-trash"></i>
                            </mdb-btn>
                        </mdb-col>
                    </mdb-row>
                </mdb-modal-body>
            </mdb-modal>
        </div>
        <!-- 編輯維修項目顏色資料 end -->
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbBtn,
  mdbSwitch,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
} from "mdbvue";
import page from "../../components/page";
export default {
  props: {
    query: {
      default: {
        search: {
          sort_active: "desc",
        },
      },
    },
  },
  components: {
    mdbCard,
    mdbCardBody,
    mdbRow,
    mdbCol,
    mdbInput,
    mdbBtn,
    mdbSwitch,
    page,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
  },
  data() {
    return {
      search: {
        sort_name: "",
        sort_brand: "",
        sort_active: "",
        page: 1,
      },
      data: {
        nowPage: 1,
        totalPage: 0,
      },
      repair_target_brandList: [],
      repair_targetList: [],
      repair_targetEmpty: {},
      edit_colors: {
        show: false,
        data: [],
        repair_target: {},
      },
    };
  },
  mounted() {
    let vue = this;
    // 把篩選的資料放到變數裡面
    for (let i in vue.search) {
      vue.search[i] = vue.query[i] ? vue.query[i] : "";
    }
    vue.search.page = vue.search.page == "" ? 1 : vue.search.page;
    vue.getData();
  },
  methods: {
    sort_data(data) {
      let new_sort_type = this.search[`sort_${data}`] == "asc" ? "desc" : "asc";

      this.search = {
        sort_name: "",
        sort_brand: "",
        sort_active: "",
        page: 1,
      };
      this.search[`sort_${data}`] = new_sort_type;
      this.searchdata();
    },
    // 搜尋資料
    searchdata() {
      let vue = this;
      let url = vue.$route.path + "?";
      for (let k in vue.search) {
        url += `${k}=${vue.search[k]}&`;
      }
      vue.$router.push(url);
      vue.getData();
    },
    getData() {
      let vue = this,
        query = [
          {
            name: "repair_target",
            data: {},
            sort: {},
            pg: vue.search.page == "" ? 1 : vue.search.page,
          },
          {
            name: "repair_target",
            data: {},
            num: "1",
          },
          {
            name: "product_class",
            data: {},
            num: "1",
          },
          {
            name: "repair_target",
          },
        ];
      if (vue.search.sort_brand != "") {
        query[0].sort["brand"] = vue.search.sort_brand == "asc" ? 0 : 1;
      } else if (vue.search.sort_name != "") {
        query[0].sort["name"] = vue.search.sort_name == "asc" ? 0 : 1;
      } else {
        query[0].sort["active"] = vue.search.sort_active == "asc" ? 0 : 1;
      }
      query[0].sort.active = vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            // console.info(res.data);
            vue.repair_targetList = res.data.repair_targetList.map((item) => {
              item.edit = false;
              item.ajaxing = false;
              item.active = item.active == "1";
              item.colors = JSON.parse(item.colors);
              return item;
            });
            res.data.repair_targetEmpty.edit = true;
            res.data.repair_targetEmpty.ajaxing = false;
            res.data.repair_targetEmpty.active = true;
            res.data.repair_targetEmpty.colors = [];
            res.data.repair_targetEmpty.id = "";
            vue.repair_targetEmpty = res.data.repair_targetEmpty;
            vue.data.totalPage = Math.ceil(res.data.repair_targetNumber / 10);
            vue.data.nowPage = vue.search.page;
          }
        });
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `product/get_repair_target_brand/`,
          },
        })
        .then((res) => {
          if (res.data.status == "data") {
            vue.repair_target_brandList = res.data.repair_target_brand;
          }
        });
    },
    add_repair_target() {
      this.repair_targetList.unshift(
        Object.assign({}, this.repair_targetEmpty, { brand: "" })
      );
    },
    edit_repair_target_colors(repair_target) {
      this.edit_colors.repair_target = repair_target;
      this.edit_colors.data = repair_target.colors;
      this.edit_colors.show = true;
    },
    save_repair_target(s) {
      let vue = this;
      if (!s.ajaxing && s.edit) {
        s.ajaxing = true;
        vue.$store
          .dispatch("post_form", {
            payload: {
              url: "product/repair_target_save",
              data: { data: JSON.stringify(s) },
            },
          })
          .then((res) => {
            if (res.data.status == "ok") {
              s.id = res.data.repair_target_id;
              s.edit = false;
              s.ajaxing = false;
            }
          });
      }
    },
  },
};
</script>